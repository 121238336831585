/* You can add global styles to this file, and also import other style files */
@import '@insurity/unity/css/styles';
@import '@insurity/unity-html/components/buttons/button.scss';

body {
  --ease-out-quart: cubic-bezier(0.165, 0.840, 0.440, 1.000);
  --transition-duration-default: 0.25s;

  overflow: hidden;
}

app-root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

// Workaround incorrect button display: contents
.unity-button {
  display: inline-flex !important;
}
